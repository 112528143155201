.admin-login-container {
  //@extend .login-container;
}

.admin-container {
  @include std-page-container;

  overflow-y: auto;
  overflow-x: hidden;

  .table-section {
    background-color: #f9f9f9;
    padding: 40px;
    position: relative;

    .table-container {
      background-color: #ffffff;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.03);
      border-radius: 20px;
      // width: 100%;
      padding: 40px;

      .header {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 2.2rem;
        color: #4b4d52;
      }

      .search-box {
        height: 50px;
        width: 250px;
        position: relative;
        input {
          padding: 10px 22px;
          height: 50px;
          border: 1.5px solid #f3f3f3;
          border-radius: 13px;
          outline: none;
          width: 100%;
          color: #898989;

          &:focus {
            color: black;
            border: 1.5px solid #737373;
          }
        }
        img {
          width: 25px;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .account-table {
      th,
      td {
        text-align: center;
      }

      th {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 1.15rem;
        color: #9d9d9d;
      }

      th.icon-col {
        width: 73px;
      }

      td.text {
        font-family: Barlow;
        font-style: normal;
        font-weight: 500;
        font-size: 1.15rem;
        color: rgba(0, 0, 0, 0.6);
      }

      td.expired {
        color: rgba(255, 0, 0, 0.6);
      }

      td.icon {
        cursor: pointer;

        i {
          color: #707070;
        }

        &:hover {
          i {
            color: #9d9d9d;
          }
        }
      }

      &.table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }

    .modal-wrapper {
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: rgba(65, 65, 65, 0.3);
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 5;

      .create-container {
        width: 100%;
        max-width: 730px;
        padding: 50px 90px;
        background-color: #ffffff;
        border-radius: 20px;
        box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.03);

        .header {
          font-family: 'Barlow';
          font-style: normal;
          font-weight: 600;
          font-size: 1.6rem;
          color: #4b4d52;
          text-align: center;
        }

        .close-cross-btn {
          position: absolute;
          cursor: pointer;
          top: 0;
          right: 0;
          z-index: 1;
          img {
            transition: transform 0.3s;
            width: 25px;
            &:hover {
              transform: scale(1.25, 1.25);
            }
          }
        }

        label {
          font-family: 'Barlow';
          font-style: normal;
          font-weight: 500;
          font-size: 1.1rem;
          color: #4b4d52;
          margin-left: 5px;
        }

        .input-box {
          position: relative;
          input {
            padding: 10px 22px;
            height: 45px;
            border: 1.5px solid #f3f3f3;
            border-radius: 13px;
            outline: none;
            width: 100%;

            font-family: Barlow;
            font-style: normal;
            font-weight: 500;
            font-size: 1.05rem;
            color: #9d9d9d;

            &:focus {
              color: #737373;
              border: 1.5px solid #737373;
            }
          }

          input::-webkit-calendar-picker-indicator {
            opacity: 0.7;
          }

          input::-webkit-calendar-picker-indicator:hover {
            cursor: pointer;
            opacity: 0.4;
          }

          .shuffle-icon {
            position: absolute;
            font-size: 20px;
            right: 13px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            color: #737373;
          }
        }
      }

      //interactives
      .input-dropdown {
        position: relative;

        .select-interactive {
          padding: 10px 20px;
          height: 45px;
          border: 1.5px solid #f3f3f3;
          border-radius: 13px;
          outline: none;
          width: 100%;

          font-family: Barlow;
          font-style: normal;
          font-weight: 500;
          font-size: 1.05rem;
          color: #9d9d9d;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          &:focus {
            color: #737373;
            border: 1.5px solid #737373;
          }
        }

        .arrow-down-icon {
          position: absolute;
          font-size: 20px;
          right: 13px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          color: #737373;
          pointer-events: none;
        }
      }
      //account rights
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        margin-bottom: 15px;
        width: 100%;
        padding: 0 5px;
        margin-top: 10px;

        .checkbox {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 17px;
          height: 17px;
          border: 1px solid #e6e6e6;
          border-radius: 5px;

          i,
          img {
            font-size: 0.6rem;
            color: white;
            opacity: 0;
          }
        }

        &.checked {
          .checkbox {
            background: #737373;
            i,
            img {
              opacity: 1;
            }
          }
        }

        &:hover {
          background-color: #f7f7f7;
        }

        .item-name {
          margin-left: 10px;
          font-family: 'Barlow';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #9d9d9d;
        }
      }

      .edit-container,
      .disable-container,
      .delete-container {
        @extend .create-container;
      }

      .disable-container,
      .delete-container {
        max-width: 650px;

        button {
          width: 100%;
          &:focus {
            outline: none;
          }
        }

        .cancel-btn {
          border: 1px solid #9d9d9d;
          border-radius: 13px;
          padding: 10px;
          font-family: Barlow;
          font-style: normal;
          font-weight: 500;
          font-size: 1.1rem;
          color: #9d9d9d;
          background-color: white;

          &:hover {
            background-color: rgb(247, 247, 247);
          }
        }

        .warning-btn {
          border-radius: 13px;
          padding: 10px;
          font-family: Barlow;
          font-style: normal;
          font-weight: 500;
          font-size: 1.1rem;
          color: white;
          background-color: #fd6464;
          border: 1px solid #fd6464;
          border: none;

          &:hover {
            background-color: #ff7575;
          }
        }
      }
    }
  }

  .error {
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: red;
  }
}

.grey-btn {
  background-color: #898989;
  border-radius: 13px;

  color: white;
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 10px 20px;
  border: none;

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #b4b4b4;
  }
}
