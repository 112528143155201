.detail-container {
  @include full-viewport;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  overflow-y: auto;

  .screenshot {
    width: 100%;
  }

  .header {
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 1.85rem;
    color: $primary-text-color;
    text-align: center;
  }

  .intro {
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 1.15rem;
    color: $primary-text-color;
    text-align: center;
  }

  .forward-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #e5e5e5;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 50%;
    }
  }
}
