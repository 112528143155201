@import 'config';
@import 'custom';
@import 'responsive';

@import './login.scss';
@import './gallery.scss';
@import './detail.scss';
@import './admin.scss';
@import './client.scss';
@import './upload.scss';
@import'./top_bar.scss';
@import './standard_text.scss';
@import './mini_game.scss';
@import './mobile_carousel.scss';
// bootstrap icons
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.0/font/bootstrap-icons.css');

.full-width-padded{
  width: 100vw;
  display: flex;
  justify-content: center;
  //overflow-y:scroll;
  .side-padding{
    width: 100%;
    padding-left: 7%;
    padding-right: 7%;
    max-width: 1440px;
    box-sizing: border-box;
    //scrollbar-width: none; /* Hide the scrollbar on Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar on Internet Explorer and Edge */
  }
  
  .side-padding::-webkit-scrollbar {
    display: none; /* Hide the scrollbar on Chrome, Safari, and Opera */
  }
}


.h-100vh {
  height: 100vh;
}

a.unstyled-link {
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.theme-link {
  color: $theme-green;
  text-decoration: underline;
}

.theme-btn-auto {
  background: #e6e6e6;
  border-radius: 20px;
  padding: 20px;

  color: rgba(75, 77, 82, 0.8);
  font-size: 1.2rem;
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  text-align: center;

  border: none;

  &:focus {
    outline: none;
  }
}

.theme-btn-full-width {
  @extend .theme-btn-auto;
  width: 100%;
}

.footer {
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 0.96rem;
  text-align: center;
  color: #9e9e9e;
}

.back-btn-wrapper__0 {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 33px;
  height: 50px;
  background: rgba(92, 92, 92, 0.78);
  border: 1px solid #f3f3f3;
  border-radius: 20px;
  z-index: 89;

  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.2;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
    background: #e5e5e5;
    color: #737374;
  }
}

.back-btn-wrapper__1 {
  position: absolute;
  top: 60px;
  left: 60px;
  color: white;
  filter: drop-shadow(0 0 0.3rem black);
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  display: flex;

  z-index: 89;

  .arrow-left {
    line-height: inherit;
    font-size: 1.1rem;
  }
}

.unclickable {
  pointer-events: none;
}

.clickable {
  cursor: pointer;
  pointer-events: initial;
}

.top-left {
  top: 0;
  left: 0;
}

.full-container-media {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}

.dropdown-checkbox-wrapper {
  position: relative;
  width: 240px;
  height: 50px;
  //background-color: white;
  border: none;
  border-bottom: 3px solid #AFAFAF;
  color: #9d9d9d;
  cursor: pointer;
  overflow-x: visible;
  .toggle {
    position: relative;
    width: 90%;
    height: 100%;
    padding: 12px 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .sort-icon {
      position: absolute;
      right: -5%;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      color: #AFAFAF;
      //pointer-events: none;
      font-size: 1.2rem;
    }
  }
  .menu {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 8px 20px 5px rgba(0, 0, 0, 0.05);
    border-radius: 13px;
    padding: 15px 0;
    color: #9d9d9d;
    display: none;

    position: absolute;
    top: 115%;
    left: 0;
    right: 0;
    z-index: 5;
    overflow-x: visible;
    overflow-y: auto;
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      margin-bottom: 15px;
      width: 100%;
      padding: 0 17px;

      .checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 17px;
        height: 17px;
        border: 1px solid #e6e6e6;
        border-radius: 5px;

        i,
        img {
          font-size: 0.6rem;
          color: white;
          opacity: 0;
        }
      }

      &.checked {
        .checkbox {
          background: #737373;
          i,
          img {
            opacity: 1;
          }
        }
      }

      &:hover {
        background-color: #f7f7f7;
      }

      .item-name {
        margin-left: 10px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 0.9rem;
      }
    }
  }

  &:focus {
    // border: 1.5px solid #737373;
    // color: #737373;

    .menu {
      display: block;
    }
  }
}



.mobile-icon {
  position: absolute;
  top: 57px;
  left: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 2;

  div {
    width: 2.5rem;
    height: 0.2rem;
    border-radius: 30px;
    transition: all 0.3s linear;
    background: #4b4d52;
    position: relative;
    transform-origin: 5px;
    filter: drop-shadow();
  }

  :first-child {
    transform: rotate(0);
  }

  :nth-child(2) {
    opacity: 1;
    transform: translateX(0);
  }

  :nth-child(3) {
    transform: rotate(0);
  }
}

.menu-sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  background-color: rgba(65, 65, 65, 0.3);
  top: 0;
  left: 0;
  z-index: 1;
  animation: fadeIn 0.3s;
  transform: translateX(-100%);

  @media (max-width: 576px) {
    width: 100%;
  }

  .menu-overlay {
    position: absolute;
    height: 100vh;
    width: 75vw;
    right: 0;
  }

  .menu-content {
    position: absolute;
    height: 100vh;
    width: 25vw;
    text-align: left;
    padding: 20px 0px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: white;
    transition: transform 0.3s ease-in-out;
    overflow-y: scroll;
    overflow-x: hidden;

    .menu-item {
      font-size: 15px;
      padding: 7px 30px;
      letter-spacing: 0.02rem;
      text-decoration: none;
      color: white;
      transition: color 0.3s linear;

      @media (max-width: 576px) {
        font-size: 1.5rem;
        text-align: center;
      }

      .logo {
        margin-top: 25%;
        margin-bottom: 10%;
        width: 100px;
      }

      .menu-item-header {
        font-family: Barlow;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.3px;
        align-items: center;
        padding-left: 16px;

        color: rgba(157, 157, 157, 0.5);
      }

      .nav-link {
        width: fit-content;
        text-decoration: none;
        font-family: Barlow;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;

        align-items: center;
        letter-spacing: 0.496657px;
        color: #737373;

        &:hover {
          color: #b8ca2b;
        }
      }
    }

    .logo_container {
      text-align: center;
      margin-bottom: 5px;
    }

    .fas {
      margin-left: 10px;
      padding: 4px;
    }

    .akn {
      padding-bottom: 5px;
      border-bottom: 1px solid white;
      margin-bottom: 1px;
    }

    .footer-wrapper {
      position: absolute;
      height: 70px;
      width: inherit;
      bottom: 0;
      padding: 0%;
      background-color: #f9f9f9;

      .footer {
        background-color: #f9f9f9;
        position: absolute;
        bottom: 30%;
        font-size: 14px;
        width: inherit;
        align-items: center;
        text-align: center;
        color: 9D9D9D;

        .bold {
          font-weight: bold;
          text-decoration: none;
        }
      }
    }

    .copyright {
      color: #f1b148;
    }
  }
}
.menu-content::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
  display: none;
}

.footer-section {
  background-color: white;
  // width: 100%;
  padding: 26px;
  text-align: center;

  b {
    font-weight: 600;
  }
}

//error page
.error-container {
  @include std-page-container;
  overflow-y: auto;
  overflow-x: hidden;
  height: 75vh;
  width: 100vw;

  .header {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 2.2rem;
    color: #4b4d52;
  }

  .error-img {
    height: 45vh;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .error-content {
    padding-top: 50px;
    margin: 0 auto;
    text-align: center;
  }

  p {
    font-size: 35px;
  }
}

.center-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

// disabled button
.disabled {
  color: #9d9d9d !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.page-item-active {
  background-color: '#4B4D52';
}


.uniform-logo{
  width: 100px;
}
