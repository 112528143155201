.upload-images-container {
  .upload-media-wrapper {
    position: relative;

    input[type='file'] {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    .add-btn {
      width: 150px;
      height: 120px;
      background: #d9d9d9;
      color: #878787;
      border-radius: 20px;
      border: none;
      padding: 10px;
      position: relative;
      text-align: center;
      padding: 25px 0;

      .bi-plus-lg {
        font-size: 40px;
      }

      &:hover {
        color: white;
        background-color: #bebebe;
      }
    }

    .image-item {
      display: inline-flex;
      flex-direction: column-reverse;
      padding: 10px;

      img {
        width: 150px;
        height: 120px;
        border-radius: 20px;
        object-fit: cover;
      }

      .remove-btn {
        width: 150px;
        height: 120px;
        position: absolute;
        border: none;
        display: none;
        text-align: center;
        border-radius: 20px;
        background: rgba(44, 44, 44, 0.35);
        color: white;

        .bi-x-circle-fill {
          font-size: 40px;
        }
      }

      &:hover .remove-btn {
        display: block;
      }
    }
  }

  .upload-error {
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: red;
  }
}

.upload-container {
  @extend .gallery-container;

  .body {
    padding: 20px;
  }

  .header {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    color: #4b4d52;
    text-align: center;
  }

  label {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    color: #4b4d52;
    margin-top: 5px;
    margin-left: 5px;

    span {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      letter-spacing: 0.496657px;
      color: #7c7f87;
    }
  }

  .input-box {
    position: relative;
    input {
      padding: 10px 22px;
      height: 45px;
      border: 1.5px solid #f3f3f3;
      border-radius: 13px;
      outline: none;
      width: 100%;

      font-family: Barlow;
      font-style: normal;
      font-weight: 500;
      font-size: 1.05rem;
      color: #9d9d9d;

      &:focus {
        color: #737373;
        border: 1.5px solid #737373;
      }
    }

    textarea {
      padding: 10px 22px;
      height: 165px;
      border: 1.5px solid #f3f3f3;
      border-radius: 13px;
      outline: none;
      width: 100%;

      font-family: Barlow;
      font-style: normal;
      font-weight: 500;
      font-size: 1.05rem;
      color: #9d9d9d;
      overflow: hidden;
      resize: none;

      &:focus {
        color: #737373;
        border: 1.5px solid #737373;
      }
    }
  }

  .input-dropdown {
    position: relative;

    .select-type {
      padding: 10px 0px;
      height: 45px;
      border: 1.5px solid #f3f3f3;
      border-radius: 13px;
      outline: none;
      width: 100%;

      font-family: Barlow;
      font-style: normal;
      font-weight: 500;
      font-size: 1.05rem;
      color: #9d9d9d;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      &:focus {
        color: #737373;
        border: 1.5px solid #737373;
      }

      .toggle {
        .sort-icon {
          right: -8%;
        }
      }
    }
    .select-category {
      @extend .select-type;
    }

    .select-difficulty {
      @extend .select-type;

      .toggle {
        .sort-icon {
          right: -5%;
        }
      }
    }

    .select-popularity {
      @extend .select-type;

      .toggle {
        .sort-icon {
          right: -5%;
        }
      }
    }
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
    width: 100%;
    padding: 0 5px;
    margin-top: 10px;

    .checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 17px;
      height: 17px;
      border: 1px solid #e6e6e6;
      border-radius: 5px;

      i,
      img {
        font-size: 0.6rem;
        color: white;
        opacity: 0;
      }
    }

    &.checked {
      .checkbox {
        background: #737373;
        i,
        img {
          opacity: 1;
        }
      }
    }

    &:hover {
      background-color: #f7f7f7;
    }

    .item-name {
      margin-left: 10px;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
    }
  }


  .upload-videos-container {
    .upload-media-wrapper {
      position: relative;

      input[type='file'] {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }

      .add-btn {
        width: 150px;
        height: 120px;
        background: #d9d9d9;
        color: #878787;
        border-radius: 20px;
        border: none;
        padding: 10px;
        position: relative;
        text-align: center;
        padding: 25px 0;

        .bi-plus-lg {
          font-size: 40px;
        }

        &:hover {
          color: white;
          background-color: #bebebe;
        }
      }

      .video-item {
        display: inline-flex;
        flex-direction: column-reverse;
        padding: 10px;

        video {
          width: 150px;
          height: 120px;
          border-radius: 20px;
          object-fit: cover;
        }

        .remove-btn {
          width: 150px;
          height: 120px;
          position: absolute;
          border: none;
          display: none;
          text-align: center;
          border-radius: 20px;
          background: rgba(44, 44, 44, 0.35);
          color: white;

          .bi-x-circle-fill {
            font-size: 40px;
          }
        }

        &:hover .remove-btn {
          display: block;
        }
      }
    }
  }

  .upload-btn {
    height: 40px;
    background: #898989;
    border: 1px solid #ced0d6;
    border-radius: 13px;
    padding: 5px 25px;

    font-family: Barlow;
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 0.95rem;
    margin-right: 1.5vw;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: #b4b4b4;
    }
  }

  .cancel-btn {
    height: 40px;
    background: #ffffff;
    border: 1px solid #ced0d6;
    border-radius: 13px;
    padding: 5px 25px;

    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 0.95rem;

    //   justify-self: flex-end;

    // margin-right: 10vw;
    margin-right: 8vw;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: #ebebeb;
    }
  }

  .error {
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: red;
  }

  .modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(65, 65, 65, 0.3);
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;

    .upload-success-container,
    .upload-error-container,
    .uploading-container {
      max-width: 650px;
      width: 100%;
      height: 40%;
      padding: 50px 90px;
      background-color: #ffffff;
      border-radius: 20px;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.03);

      .header {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        color: #4b4d52;
        text-align: center;
      }

      .close-cross-btn {
        position: absolute;
        cursor: pointer;
        top: 0;
        right: 0;
        z-index: 1;
        img {
          transition: transform 0.3s;
          width: 25px;
          &:hover {
            transform: scale(1.25, 1.25);
          }
        }
      }

      label {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 1.1rem;
        color: #4b4d52;
        margin-left: 5px;
      }

      button {
        width: 100%;
        &:focus {
          outline: none;
        }
      }

      .cancel-btn {
        border: 1px solid #9d9d9d;
        border-radius: 13px;
        padding: 10px;
        font-family: Barlow;
        font-style: normal;
        font-weight: 500;
        font-size: 1.1rem;
        color: #9d9d9d;
        background-color: white;

        &:hover {
          background-color: rgb(247, 247, 247);
        }
      }

      .warning-btn {
        border-radius: 13px;
        padding: 10px;
        font-family: Barlow;
        font-style: normal;
        font-weight: 500;
        font-size: 1.1rem;
        color: white;
        background-color: #fd6464;
        border: 1px solid #fd6464;
        border: none;

        &:hover {
          background-color: #ff7575;
        }
      }
    }
  }
}
