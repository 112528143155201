.mobile-carousel-wrapper{
    //border-radius: 30px 0px 0px 0px;
    display: flex;
    justify-content: center;
    margin-right: 60px; //to combat the next button
    img.mobile-frame{
        position: absolute;
        z-index: 1;
        pointer-events: none;
        //left: 5px;
        //width: 300px;
        //height: auto;
    }

    .mobile-carousel {
        width: 100%;
        height: 100%;
        z-index: 0;
        width: 292px;
        height: 600px;
        .carousel-inner{
            border-radius: 55px;
            width: 100%;
            height: 100%;
        }
        .carousel-item{
            width: 100%;
            height: 100%;
        }
        
        .chevron{
            color: black;
            font-size: 50px;
        }
        
        .carousel-control-prev{
            left: -60px;
        }
        .carousel-control-next{
            right: -60px;
        }

        .carousel-indicators{
            bottom: -50px;
            li{
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #AFAFAF;

                &.active{
                    background-color: #D4E26D;
                }
            }
        }
        
        img,
        iframe {
            width: 100%;
            height: 100%;
            object-fit: contain; /* Ensure the content is contained */
        }
    }
}

.mobile-asset-wrapper{
    // display: flex;
    // align-items: center;
    // justify-content: center;
    margin-right: 60px;
    position: relative;
    display: inline-block;
    //border-radius: 55px;
    //overflow: hidden;

    img.mobile-frame{
        max-width: 292px;
        max-height: 600px;
        display: block;
        z-index: 2;
        position: relative;
    }

    img.mobile-content {
        z-index: 1;
        padding: 5px 8px 5px 8px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        //height: 100%;
        object-fit: cover; /* Ensure the image covers the container */
        //border-radius: 65px; /* Match the border-radius of the frame */
        clip-path: inset(0 round 50px); /* Clip the content to match the rounded corners */
      }

    // .mobile-content-container{
    //     position: absolute;
    //     z-index: 2;
    //     pointer-events: none;
    //     width: 100%;
    //     height: 100%;
        
    //     overflow: hidden;

    //     img.mobile-content{
    //         width: 100%;
    //         height: 100%;
    //         object-fit: contain;
    //         object-position: center;
    //     }
    // }
}