html,
body {
  font-size: 12px;
}

@include media-sm {
  html,
  body {
    font-size: 13px;
  }
}

@include media-md {
  html,
  body {
    font-size: 14px;
  }
}

@include media-lg {
  html,
  body {
    font-size: 16px;
  }
}

@include media-xl {
}
