.green-background-circle{
    color: #0E0E0E; 
    border-radius: 50%; 
    width:16px;
    height:16px;
    padding: 20px;
    background-color: #D4E26D;
    &.active{
        background-color: #B4D653;
    }

}

.brand-box{
    cursor: pointer;
    border: 2px solid #AFAFAF;
    margin: -1px;
    height: 222px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        max-width: 80%;
        max-height: 80%;
        width: auto;
        height: auto;
        object-fit: contain;
    }

    i{
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(35%);
        bottom: 0;
        font-size: 40px;
        color: #EFEFEF;
        display: none;

        &.active{
            display: block;
        }
    }
}

.client-info-box{
    background-color: #EFEFEF;
    margin-right: -16px;
    margin-left: -16px;
    height: 593px;
}

.green-circle{
    width: 30px;
    height: 30px;
    padding: 25px;
    border-radius: 50%;
    background-color: #E6EDAC;
    z-index: -99;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-right: -5px;
    transform: translateX(-15px);
    // span{
    //     margin-left: -25px;
    // }
}