//$small-font-size: 12px;
$small-font-size: 14px;

.main-header{
    font-family: butlerlight;
    font-size: 54px;
    font-weight: 300;
    line-height: 50px;
    text-align: left;
    color: #0E0E0E;
    margin-top: 32px;
}

.sub-header{
    font-family: butlerlight;
    font-size: 30px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    color: #0E0E0E;
    margin-top: 32px;
}

.info-text{
    font-family: Open Sans;
    font-size: $small-font-size;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #0E0E0E;
    margin-top: 32px;
    overflow-wrap: break-word;
    white-space: normal;
    //text-wrap: wrap;
}

.grey-top-border{
    border-top: 1.5px solid #AFAFAF;
}


.medium-grey-text{
    //styleName: Smaller title;
    font-family: Butlerlight;
    font-size: 24px;
    font-weight: 300;
    line-height: 28.8px;
    text-align: left;
    color:#AFAFAF;
}

.medium-black-text{
    //styleName: Smaller title;
    font-family: Butlerlight;
    font-size: 24px;
    font-weight: 300;
    //line-height: 28.8px;
    text-align: left;
    color:#0E0E0E;
    margin-bottom: -5px;
}

.highlighted-text{
    margin-left:-5px;
    line-height: 1.0;
    text-transform: capitalize;
}

.standard-button{
    border: none;
    padding: 15px 0px;
    border-radius: 100px;
    width: 200px;
    //height: 56px;
    background-color: #D4E26D;

    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #0E0E0E;

    &:hover{
        background-color: #B4D653;
    }
}

.theme-label{
    font-family:Open Sans;
    letter-spacing: 0.1em;
    font-weight: 600;
    font-size:12px;
    color:#808080;
    text-transform: uppercase;
}

.tagline{
    font-family:Open Sans;
    font-weight:400;
    color:#808080;
    font-size:$small-font-size;
}

.ul-no-padding{
    list-style-position: inside;
    padding: 0;
}