.login-form{
  .input-field{
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 45px;
    text-align: left;
    border: 0;
    box-shadow: 0px 4px 4px 0px #00000040 inset;
    width: 80%;
    padding: 0 10px;
    
    &:focus{
      box-shadow:none;
      outline-color: #B4D653;
    }
  }

  .error {
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: red;
  }

}
  

// .brand-carousel{
//   overflow: hidden;
//   white-space: nowrap;

//   .carousel-container{
//     margin-top: 15px;
//     display: flex;
//     overflow: visible;
//     align-items: center;
//     justify-content: flex-start;
//     display: inline-block;
//     animation: scroll 25s linear infinite;
    

//     @keyframes scroll {
//       0% {
//         transform: translateX(0%);
//       }
//       50% {
//           transform: translateX(-110%);
//       }
//       50.001% {
//           transform: translateX(100%);
//       }
//       100% {
//           transform: translateX(0%);
//       }
//     }

//     .item{
//       //width: 200px;
//       //height: 110px;
//       //padding: 30px;
//       display: inline-block;
//       img{
//         height: 90px;
//         width: auto;
//         object-fit: contain;
//         object-position: center;
//         //max-width: 160px;
//         //max-height: 90px;
//       }
//     }
//   }

// }

.banner{
  //width: 200px;
  //height: 110px;
  //padding: 30px;
  height: 100%;
  display: inline-block;
  img{
    //height: 90px;
    height: 100%;
    width: auto;
    object-fit: contain;
    object-position: center;
    //max-width: 160px;
    //max-height: 90px;
  }
}


.looping-banner {
  overflow: hidden;
  width: 100%;
}

.looping-banner-content {
  display: flex;
  flex-direction: row;
  //animation: scroll 20s linear infinite;
}

.looping-banner-content > div {
  flex-shrink: 0;
}

// @keyframes scroll {
//   from {
//     transform: translateX(0);
//   }
//   to {
//     transform: translateX(-50%);
//   }
// }
