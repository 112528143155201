.gallery-container {
  width: 100%;

  overflow-y: auto;
  overflow-x: hidden;

  .welcome-header{
    font-family: Butlerlight;
    font-size: 68px;
    font-weight: 300;
    line-height: 68px;
    text-align: left;

    color: #0E0E0E;

    span{
      font-size: 54px;
      color: #AFAFAF;
    }
  }

  // .intro-cell{
  //   border-top: 3px solid #AFAFAF;

  //   // h3{
  //   //   font-family: Butler;
  //   //   font-size: 30px;
  //   //   font-weight: 300;
  //   //   line-height: 30px;
  //   //   text-align: left;
  //   //   color: #0E0E0E;
  //   //   margin-top: 30px;
  //   // }

  //   // p{
  //   //   font-family: Open Sans;
  //   //   font-size: 14px;
  //   //   font-weight: 400;
  //   //   line-height: 21px;
  //   //   text-align: left;
  //   //   color: #0E0E0E;
  //   //   margin-top: 30px;
  //   // }
  // }

  .body {
    //padding: 42px;
    //background-color: #f9f9f9;

    .filters-wrapper {
      position: relative;
      width: 88%;
      display: flex;
      flex-direction: row;
      max-width: 850px;
      .search-box {
        position: relative;
        flex-grow: 1;
        input {
          //styleName: Body/Main/18;
          font-family: 'Open Sans';
          font-size: 18px;
          font-weight: 400;
          line-height: 45px;
          text-align: left;


          padding: 10px 22px;
          height: 50px;
          border: none;
          border-bottom: 3px solid #AFAFAF;
          //border-radius: 13px;
          outline: none;
          width: 100%;
          background-color: #F8F8F8;

          // &:focus {
          //   border: 1.5px solid #737373;
          // }
        }
        // img {
        //   width: 25px;
        //   position: absolute;
        //   right: 15px;
        //   top: 50%;
        //   transform: translateY(-50%);
        // }
      }

      // .ordering-toggle-wrapper {
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   cursor: pointer;
      //   color: #9d9d9d;

      //   i {
      //     font-size: 30px;
      //   }
      // }
    }

    .tagBox {
      height: 32px;
      padding: 12px;
      background: #737374;
      border-radius: 13px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #f3f3f3;
      cursor: pointer;

      span {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 0.9rem;
      }

      i {
        font-size: 12px;
      }
    }

    .scroll-section {
      overflow-y: auto;
      // height: 55vh; removed footer
      min-height: 250px;
    }

    // .tabs-wrapper {
    //   display: flex;
    //   justify-content: center;

    //   .tab {
    //     font-family: Barlow;
    //     font-style: normal;
    //     font-weight: 600;
    //     font-size: 1.55rem;
    //     color: rgba($color: $primary-text-color, $alpha: 0.4);
    //     overflow: visible;
    //     position: relative;
    //     cursor: pointer;

    //     &.active {
    //       color: $primary-text-color;

    //       &::after {
    //         content: "";
    //         position: absolute;
    //         width: 60px;
    //         height: 2.5px;
    //         top: 100%;
    //         left: 50%;
    //         transform: translateX(-50%);
    //         background-color: $primary-text-color;
    //       }
    //     }

    //     &__right {
    //     }
    //     &__left {
    //       margin-left: 15%;
    //     }
    //   }
    // }
  }
}

.thumbnail-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .image-wrapper {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 56.25% 0 0 0;

    img {
      object-position: center;
      object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  // .name {
  //   font-family: Barlow;
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 1.3rem;
  //   color: $primary-text-color;
  //   text-align: center;

  //   padding: 20px 25px;
  // }
}
