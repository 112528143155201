.topbar {
    //background-color: white;
    .signout-btn {
        background-color: #808080;
        border-radius: 100px;
        width: 124px;
        height: 56px;
        color: white;
        font-family: Open Sans;
        text-align: center;
        border-width: 0;
        &:focus {
            outline: none;
        }
    
        &:hover {
            background-color: #afafaf;
        }
    }

    img.logo{
        cursor: pointer;
        width: 100px;
    }

    .sidebar-toggle{
        cursor:pointer;
    }
  
    .logo-wrapper {
      img {
        width: 110px;
      }
    }
  
    //update
    .bi {
      font-size: 0.95rem;
      padding-right: 2px;
    }
  
    .add-interactive-btn {
      height: 40px;
      background: #898989;
      border: 1px solid #ced0d6;
      border-radius: 13px;
      padding: 2px 15px;
      width: max-content;
  
      font-family: Barlow;
      color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 85%;
      margin-right: 1.5vw;
  
      &:focus {
        outline: none;
      }
  
      &:hover {
        background-color: #b4b4b4;
      }
    }
  }