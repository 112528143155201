@mixin full-viewport {
  width: 100vw;
  height: 100vh;
}

@mixin std-page-container {
  @include full-viewport;
  //background-color: #e5e5e5;
}


.no-hover-underline:hover {
  text-decoration: none;
}