@mixin media-sm {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin media-md {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin media-lg {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin media-xl {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin media-xxl {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

$primary-text-color: #4b4d52;

$theme-green: #c3d600;
